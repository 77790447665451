import React from "react"
import Hpto from "./hpto"
import { Link } from "gatsby"


//This list is for the overview
const HptoList = ({ data }) => {
  return (
    <>
      <div>The List</div>
      {data.map(hpto => (
        <Link key={hpto._id} to={hpto._id}>
          <Hpto data={hpto}></Hpto>
        </Link>
      ))}
    </>
  )
}

export default HptoList
