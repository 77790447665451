import React from "react"

const Hpto = props => {
  let companyLogo
  let spotifyLogo
  if (props.blok.company_logo != null) {
    companyLogo = (
      <img
        alt=""
        style={{ paddingLeft: "48px" }}
        src={props.blok.company_logo.filename}
      ></img>
    )
  }
  if (props.blok.listen_on_spotify_logo != null) {
    spotifyLogo = (
      <img
        alt=""
        style={{ paddingRight: "48px" }}
        src={props.blok.listen_on_spotify_logo.filename}
      ></img>
    )
  }
  const playListContainer = (
    <div className="playlist-container">
      <a
        className="playlist-cover"
        href={props.blok.spotify_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="" src={props.blok.interactive_area_image.filename}></img>
      </a>
    </div>
  )
  if (props.type === "editor") {
    return (
      <div className="hpto-container">
        <div className="background">
          <img alt="" src={props.blok.background.filename}></img>
        </div>
        <div className="absolute-container">
          <div className="logo-container">
            {companyLogo}
            {spotifyLogo}
          </div>
        </div>
        <div className="absolute-container">{playListContainer}</div>
      </div>
    )
  } else {
    return playListContainer
  }
}

export default Hpto
