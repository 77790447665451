import React from "react"
import Components from "./components.js"

const Hpto = props => {
  let companyLogo
  let spotifyLogo
  if (props.blok.company_logo != null) {
    companyLogo = (
      <img
        alt=""
        style={{ paddingLeft: "48px" }}
        src={props.blok.company_logo.filename}
      ></img>
    )
  }
  if (props.blok.listen_on_spotify_logo != null) {
    spotifyLogo = (
      <img
        alt=""
        style={{ paddingRight: "48px" }}
        src={props.blok.listen_on_spotify_logo.filename}
      ></img>
    )
  }
  const playlistContainer = (
    <div className="playlist-container">
      {props.blok.playlists.map(playlist =>
        React.createElement(Components(playlist.component), {
          key: playlist._uid,
          blok: playlist,
        })
      )}
    </div>
  )
  if (props.type === "editor") {
    return (
      <div className="hpto-container">
        <div className="background">
          <img alt="" src={props.blok.background.filename}></img>
        </div>
        <div className="absolute-container">
          <div className="logo-container">
            {companyLogo}
            {spotifyLogo}
          </div>
        </div>
        <div className="absolute-container">{playlistContainer}</div>
      </div>
    )
  } else {
    return playlistContainer
  }
}

export default Hpto
