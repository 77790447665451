import React from "react"
import PlayButton from "../images/play-button.svg"

const Playlist = props => {
  return (
    <a
      className="playlist-cover"
      href={props.blok.spotify_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt=""
        className="playlist-image"
        src={props.blok.cover_image.filename}
      ></img>
      <img className="playlist-hover" src={PlayButton}></img>
    </a>
  )
}
export default Playlist
