module.exports = {
  siteMetadata: {
    title: `RecordJet HPTO`,
    description: ``,
    author: `@dorfmeyster`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `RecordJet Homepage Takeover`,
        short_name: `recordjet-hpto`,
        start_url: `/`,
        background_color: `#FFFFFF`,
        theme_color: `#FFFFFF`,
        display: `minimal-ui`,
        icon: `src/images/favicon.png`, // This path is relative to the root of the site.
      },
    },
    // Simple config, passing URL
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'yFChjWrQrJZNTALeqGPGMgtt',
        homeSlug: '/',
        version: 'draft'
      }
    }
  ],
}
