import Page from "./page"
import Hpto from "./hpto"
import HptoSingle from "./hpto-single"
import HptoList from "./hpto-list"
import Playlist from "./playlist"
import ComponentNotFound from "./component_not_found"

const ComponentList = {
  page: Page,
  Hpto: Hpto,
  HptoSingle: HptoSingle,
  HptoList: HptoList,
  Playlist: Playlist,
}
const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
